import React, { useState, useEffect } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { RiMenu5Fill } from "react-icons/ri";
import { HashLink } from 'react-router-hash-link';

const Nav = () => {
const [open, setOpen] = useState(false)
const [prevScrollPos, setPrevScrollPos] = useState(0);
const [visible, setVisible] = useState(true)

const handleScroll = () => {
    const currentScrollPos = window.scrollY
    if(currentScrollPos === 0){
      setVisible(true)
    }else if(currentScrollPos > prevScrollPos && !open){
      setVisible(false)
    }else{
        setVisible(true)
    }
    setPrevScrollPos(currentScrollPos)
}

useEffect( () => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll)
})

  return ( 
    // <nav className={`${visible ? 'visible' : 'hidden'} ${window.scrollY === 0 && !open ? "bg-transparent": "bg-th-white lg:bg-th-white shadow-md"}  fixed z-50 top-0 w-screen left-1/2 -translate-x-1/2 xl:px-2 px-0 pt-3 pb-2 md:pt-1 xl:pt-2 md:pb-1 overflow-hidden transition-all duration-500`}>
    <nav className={`visible ${window.scrollY === 0 && !open ? "bg-transparent": "bg-th-white lg:bg-th-white shadow-md"}  fixed z-50 top-0 w-screen left-1/2 -translate-x-1/2 xl:px-2 px-0 pt-3 pb-2 md:pt-1 xl:pt-2 md:pb-1 overflow-hidden transition-all duration-500`}>
    <div className={`text-white w-screen px-2 lg:px-5 2xl:px-14 flex justify-between xl:justify-center items-center flex-wrap xl:flex-nowrap gap-0 md:gap-1 xl:gap-0 mx-auto font-primary lowercase text-base tracking-widest`}>
    <a href="/" className="block mr-0 lg:mr-10 py-0 lg:py-2">
    {window.scrollY === 0 && !open ?
    <img src={`/assets/images/logos/farmhouse_logo_white.png`} className="w-[90px] md:w-[140px] md:mx-auto h-auto max-h-[35px] lg:max-h-[90px] object-contain" alt="Farmhouse Logo" />
    :
    <img src={`/assets/images/logos/farmhouse_logo_red.png`} className="w-[90px] md:w-[140px] md:mx-auto h-auto max-h-[35px] lg:max-h-[90px] object-contain" alt="Farmhouse Logo" />
    }
    </a>
    <div className="block xl:hidden pr-2">
    {open ? 
    <div className={`${window.scrollY  === 0 && !open ? "text-white": "text-black"} cursor-pointer`} onClick={() => setOpen(prev => !prev)}>
    <IoCloseSharp size={25} />
    </div>
    :
    <div className={`${window.scrollY=== 0  ? "text-white": "text-black"} cursor-pointer`} onClick={() => setOpen(prev => !prev)}>
    <RiMenu5Fill size={25} />
    </div>
  }
    </div>
    <div className={`${open ? 'translate-x-0 min-h-screen': 'translate-x-[1000px] xl:translate-x-0 min-h-0'} bg-th-white lg:bg-transparent w-screen h-0 xl:h-auto xl:w-7/12 transition-all duration-500`}>
      <ul className={`${window.scrollY === 0 ? "text-black xl:text-white": "text-black"} h-screen xl:h-auto justify-start md:justify-center space-y-3 xl:space-y-0 xl:justify-around flex flex-col items-center p-0 lg:p-4 mt-12 xl:flex-row space-x-0 lg:space-x-2 md:mt-0`}>
        <li>
          <a href='https://www.tbdine.com/book/restaurant/the-farmhouse-restaurant?idApp=70973&language=en-us' target='_blank'rel="noreferrer" className="block py-2 pr-4 pl-3 border-transparent hover:border-th-yellow border-t-0 lg:border-t-2 whitespace-nowrap lowercase">RESERVE NOW</a>
        </li>
        <li>
          <HashLink smooth to="/#about" onClick={() => setOpen(false)} className="block py-2 pr-4 pl-3 border-transparent hover:border-th-yellow border-t-0 lg:border-t-2 whitespace-nowrap lowercase">About</HashLink>
        </li>
        <li>
          <HashLink smooth to="/#menus"  onClick={() => setOpen(false)} className="block py-2 pr-4 pl-3 border-transparent hover:border-th-yellow border-t-none border-t-0 lg:border-t-2 whitespace-nowrap lowercase">Menus</HashLink>
        </li>
        <li>
          <HashLink smooth to='/#reservations' onClick={() => setOpen(false)} className="block py-2 pr-4 pl-3 border-transparent hover:border-th-yellow border-t-none border-t-0 lg:border-t-2 whitespace-nowrap lowercase">Reservations</HashLink>
        </li>
        <li>
        <HashLink smooth to='/contact' onClick={() => setOpen(false)} className="block py-2 pr-4 pl-3 border-transparent hover:border-th-yellow border-t-none border-t-0 lg:border-t-2 whitespace-nowrap lowercase">Contact</HashLink>
        </li>
        <li>
          <a href='https://www.viaggiohospitality.com/giftcards' target='_blank'rel="noreferrer" className="block py-2 pr-4 pl-3 border-transparent hover:border-th-yellow border-t-none border-t-0 lg:border-t-2 whitespace-nowrap lowercase">Giftcards</a>
        </li>
        {/* <li>
          <a href='https://www.tbdine.com/book/restaurant/the-farmhouse-restaurant?idApp=70973&language=en-us' target='_blank'rel="noreferrer" className={`block ${window.scrollY === 0 ? "bg-th-red lg:bg-transparent": "bg-th-red"} text-white py-2 px-3 rounded-md whitespace-nowrap lowercase transition-all hover:bg-black hover:text-white`}>RESERVE NOW</a>
        </li> */}
      </ul>
    </div>
  </div>
</nav>
  );
};

export default Nav;
