import React from 'react'

const FooterSub = () => {
  return (
  <div className='font-secondary text-xs flex flex-col justify-center items-center text-center py-2 lg:py-5 text-black'>
    <p>2022© The Farmhouse Vancouver</p>
    <a href='https://www.viaggiohospitality.com/' className='py-1'><img src="assets/images/logos/viaggio_logo.png" alt="viaggio-logo" className='w-28 object-contain' /></a>
    <p>Part of the award-winning hospitality group, Viaggiohospitality.com<br/>
    Powered by Niradia Tech</p>
    </div>
  )
}

export default FooterSub