import React from 'react'

const Menus = () => {
  return (
    <div id="menus" className='w-screen lg:w-full xl:w-11/12 my-20 mx-auto'> 
    <h2 className='font-primary lowercase text-4xl text-th-brown font-extrabold tracking-widest text-center'>MENUS</h2>
   <div className="h-fit flex flex-col lg:flex-row justify-center items-center gap-10 lg:gap-3 2xl:gap-10 mt-10">
   <div className='w-11/12 xl:w-1/2 h-full md:h-[350px] lg:h-[400px] 2xl:h-[310px] bg-th-red flex flex-col md:flex-row'>
      <img src="assets/images/menu.jpg"  className='object-cover w-full md:w-1/2 h-full max-h-[250px] md:max-h-[800px]'alt="dinner_menu" />
    <div className='p-5 w-full md:w-1/2 flex flex-col justify-between items-center gap-5 md:gap-1'>
    <h2 className=' font-primary lowercase text-2xl lg:text-3xl text-th-brown font-extrabold tracking-widest'>food</h2>
    <p className='text-base font-secondary text-white text-center'>Sample authentic Italian antipasti, mains, and desserts crafted with seasonal, local ingredients.</p>
    <div className='flex flex-col lg:flex-row gap-2 lg:gap-5 justify-center items-center text-center'>
    <a href='assets/menus/The Farmhouse - Main Menu.pdf' target='_blank'rel="noreferrer" className="mb-0 lg:mb-1 font-primary lowercase text-lg cursor-pointer bg-th-brown text-th-white px-6 py-1 rounded-md transition-all hover:bg-white hover:text-th-brown">food</a>
    <a href='assets/menus/The Farmhouse - Dessert.pdf' target='_blank'rel="noreferrer" className="mb-0 lg:mb-1 font-primary lowercase text-lg cursor-pointer bg-th-brown text-th-white px-6 py-1 rounded-md transition-all hover:bg-white hover:text-th-brown">dessert</a>
    </div>
      </div>
    </div>
    <div className='w-11/12 xl:w-1/2 h-full md:h-[350px] lg:h-[400px] 2xl:h-[310px] bg-th-red flex flex-col md:flex-row'>
        <img src="assets/images/wine.jpg"  className='object-cover w-full md:w-1/2 h-full max-h-[250px] md:max-h-[800px]' alt="dinner_menu" />
      <div className='p-5 w-full md:w-1/2 flex flex-col justify-between items-center gap-5 md:gap-0'>
    <h2 className=' font-primary lowercase text-2xl lg:text-3xl text-th-brown font-extrabold tracking-widest'>beverages</h2>
    <p className='text-base font-secondary text-white text-center'>The curated wine list features natural, organic, and biodiverse wine from BC and Italy, carefully selected to pair with our dishes. Here, there's something for everyone with classic Italian cocktails, beers, and non-alcoholic drinks.</p>
    <div className='flex flex-col lg:flex-row gap-2 lg:gap-5 justify-center items-center text-center'>
    <a href='assets/menus/The Farmhouse - Wine BTG.pdf' target='_blank'rel="noreferrer" className="mb-0 lg:mb-1 font-primary lowercase text-lg cursor-pointer bg-th-brown text-th-white px-6 py-1 rounded-md transition-all hover:bg-white hover:text-th-brown">wine</a>
    <a href='assets/menus/The Farmhouse - Beverages.pdf' target='_blank'rel="noreferrer" className="mb-0 lg:mb-1 font-primary lowercase text-lg cursor-pointer bg-th-brown text-th-white px-6 py-1 rounded-md transition-all hover:bg-white hover:text-th-brown">beverages</a>
    </div>
      </div>
    </div>
   </div>
    </div>
  )
}

export default Menus