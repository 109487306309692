import React from 'react'
import { RiTwitterFill,RiInstagramLine, RiFacebookCircleFill, RiPhoneFill, RiMailFill, RiMapPin2Fill } from "react-icons/ri";

const Footer = () => {
  return (
    <div className='bg-th-red w-screen mx-auto pb-10 lg:pb-20 pt-10 lg:pt-5'>
        <div className='w-10/12 xl:w-9/12 border-t border-white mx-auto'>
            <div className='flex justify-between items-start lg:items-center flex-col gap-5 lg:gap-0 lg:flex-row pt-10 lg:pt-20'>
            <a href='/'><img src="assets/images/logos/farmhouse_logo_second.png" alt="farmhouse_logo" 
                className='w-40 lg:w-52 object-contain'
            /></a>
            <div className='flex items-start justify-center'>
              <p className='text-white text-lg font-primary lowercase text-left'>
              opening hours
              <br/>
              <span className='font-secondary text-sm block capitalize'>  4pm - 11pm Friday and Saturday<br/>
              4pm - 10pm Wednesday, Thursday and Sunday
              </span>
            </p>
            </div>
            <div className='flex flex-col justify-center items-start text-white font-secondary text-sm gap-1'>
            <a href='tel: 604-260-2477' className='flex items-center justify-start gap-1'><RiPhoneFill className='text-base' />604.260.2477</a>
            <a href="mailto:info@farmhouseon10th.com" className='flex items-center justify-start gap-1'><RiMailFill className='text-base' /> info@farmhouseon10th.com</a>
            <a href="https://goo.gl/maps/tDaMXyVGrdqNcr4a7" target="_blank" rel="noreferrer" className='text-white font-secondary text-sm flex items-center justify-start gap-1'><RiMapPin2Fill className='text-base' /> 352 E 10th Ave, Vancouver, BC</a>
            </div>
            <div className='flex gap-2 items-center'>
                <a href="https://www.facebook.com/farmhouseyvr" target="_blank" rel="noreferrer"><RiFacebookCircleFill className='text-white fill-white text-3xl hover:fill-th-brown' /></a>
                <a href="https://www.instagram.com/farmhouseon10th" target="_blank" rel="noreferrer"><RiInstagramLine className='text-white fill-white text-3xl hover:fill-th-brown' /></a>
                <a href="https://twitter.com/thefarmhouseyvr" target="_blank" rel="noreferrer"><RiTwitterFill className='text-white fill-white text-3xl hover:fill-th-brown' /></a>
            </div>
            </div>
        </div>
    </div>
  )
}

export default Footer