import React from 'react'

const About = () => {
  return (
    <div id="about" className='w-11/12 2xl:w-8/12 mx-auto mt-14 lg:mt-20 flex items-center justify-center flex-col lg:flex-row'>
        <div className='w-full lg:w-1/3 text-center lg:text-left'>
        <h3 className='text-3xl lg:text-4xl font-primary text-th-brown tracking-wider font-extrabold'>about us</h3>
        <h4 className='text-2xl lg:text-3xl font-secondary mt-5 lg:mt-14'>West Coast,<br /> Meet Italy</h4>
        </div>
        <div className='w-full lg:w-2/3 font-secondary'>
            <p className='mt-5 leading-relaxed'><span className='font-extrabold'>The Farmhouse exists for one reason: </span> To bring people together. And we believe the best way to do that is over good wine and shared food. The Farmhouse is an intimate, rustic Vancouver restaurant featuring Northern Italian fare in a homey, comfortable space.</p>
            <p className='mt-2 lg:mt-5 leading-relaxed'>While Italian flavours brought us here, the bounty of the West Coast keeps us true to our roots. Growers, farmers, and foragers from across the valley stock our kitchen because, for us, the most honest ingredients come from the earth you stand on.</p>
            <p className='mt-2 lg:mt-5 leading-relaxed'><span className='font-extrabold'>About our name: </span>The Farmhouse embodies who we are. Simple, rustic, and casual, everyone and anyone can find solace and company inside a farmhouse. Comforting yet elevated, casual yet intimate, you can expect a dining experience that is somehow both familiar and fine at The Farmhouse.</p>
            <p className='mt-2 lg:mt-5 leading-relaxed'><span className='font-extrabold'>Our vision is simple: </span>bring British Columbia the cuisine and comfort we love from Italy. This restaurant is both an homage to our roots and a celebration of the place we now call home.</p>
        </div>
    </div>
  )
}

export default About