import './App.css';
import About from './components/About';
import Footer from './components/common/Footer';
import FooterSub from './components/common/FooterSub';
import Nav from './components/common/Nav';
import Contact from './components/Contact';
import Family from './components/Family';
import FoodWine from './components/FoodWine';
import Menus from './components/Menus';
import Reservations from './components/Reservations';

function App() {
  return (
    <div className="min-h-screen overflow-x-hidden">
     <Nav />
    <section className='relative bg-black w-screen h-[450px] lg:h-[550px] 2xl:h-[650px] rounded-bl-[170px] lg:rounded-bl-[220px] overflow-hidden'>
    {/* <div className='absolute top-0 left-0 bg-hero bg-no-repeat bg-cover w-full h-full bg-opacity-25 opacity-60 bg-center 2xl:bg-[center_bottom_-450px]'></div> */}
    <div className='absolute top-0 left-0 bg-hero bg-no-repeat bg-cover w-full h-full bg-opacity-25 opacity-60 bg-center'></div>
      <img src='/assets/images/logos/farmhouse_logo_second.png' alt="farmhouse_logo" className='absolute w-2/3 lg:w-1/3 left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 object-contain' />
      <a href='https://www.tbdine.com/book/restaurant/the-farmhouse-restaurant?idApp=70973&language=en-us' target='_blank'rel="noreferrer" className={` absolute left-1/2 -translate-x-1/2 bottom-20 lg:bottom-32 tracking-widest text-base lg:text-xl cursor-pointer font-primary bg-th-red text-white py-2 px-3 rounded-md whitespace-nowrap lowercase transition-all hover:bg-white hover:text-th-red`}>RESERVE NOW</a>
    </section>
    <About />
    <Menus />
    <FoodWine />
    <Family />
    <Reservations />
    {/* <Contact /> */}
    <Footer />
    <FooterSub />
    </div>
  );
}

export default App;
